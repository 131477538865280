import BaseCollection from "@sourceflow-uk/sourceflow-sdk/base_collection";
import { flatten } from "lodash/array";

export default class CollectionHelper {
  collection;

  constructor(data) {
    this.collection = new BaseCollection(data, "en");
  }

  find(value, key = "url_slug") {
    if (Array.isArray(value)) {
      return value.map((v) => this.find(v, key));
    }

    return this.collection.getItems().find((i) => i[key].toLowerCase() === value.toLowerCase());
  }

  findByName(value, key = "name") {
    if (Array.isArray(value)) {
      return value.map((v) => this.find(v, key));
    }

    return this.collection.getItems().find((i) => i[key].toLowerCase() === value.toLowerCase());
  }

  nestedFind(values, key = "url_slug") {
    const [value, ...rest] = [...values].reverse();
    const pages = this.filter((i) => i[key] === value);

    // if only 1 result return it
    if (pages.length === 1) {
      return pages[0];
    }

    // if no parent find the result with no parent
    if (rest.length === 0) {
      return pages.find((i) => i.parent.id === null);
    }

    // find parent and return item with matching parent
    const parent = this.nestedFind(rest.reverse(), key);
    if (parent) {
      return pages.find((p) => p.parent.id === parent.id);
    }

    return null;
  }

  getNestedUrlSlugsForPage(url_slug) {
    return this.getValuesArrayFromNested(url_slug, "url_slug");
  }

  getValuesArrayFromNested(value, key) {
    let values = [value];
    let page = this.find(value, key);

    while (page.parent.id !== null) {
      page = this.find(page.parent.id, "id");

      values = [page[key], ...values];
    }

    return values;
  }

  findWithNestedChildren(value, key) {
    const page = this.find(value, key);

    return {
      ...page,
      children: this.fetch({
        parent: page.id,
        map: (c) => this.findWithNestedChildren(c.id, "id"),
      }),
    };
  }

  pluckDeep(value, key, pluck) {
    const _pd = (v, k) => {
      const page = this.find(v, k);

      if (!page) {
        return [];
      }

      const children = this.fetch({ parent: page.id });

      return children ? [page[pluck], ...children.map((child) => _pd(child[k], k, pluck))] : [page[pluck]];
    };

    return flatten(_pd(value, key));
  }

  filter(filter) {
    return this.fetch({ filter });
  }

  fetch({ limit = null, parent = false, exclude = null, filter = null, map = null } = {}) {
    let items = this.collection.getItems();

    if (parent !== false) {
      items = items.filter((i) => i.parent.id === parent);
    }

    if (exclude) {
      items = items.filter((i) => !exclude.includes(i.id));
    }

    if (filter) {
      items = items.filter(filter);
    }

    if (limit) {
      items = items.slice(0, limit);
    }

    if (map) {
      items = items.map(map);
    }

    return items;
  }

  toPaths({ exclude = [], includes = [], iterator = null } = {}) {
    const defaultIterator = ({ url_slug }) => ({ params: { url_slug } });

    return this.collection
      .getItems()
      .filter((i) => (includes.length > 0 ? includes.includes(i.id) : true))
      .filter((i) => !exclude.includes(i.id))
      .map(iterator ?? defaultIterator);
  }

  toNestedPaths({ exclude = [], includes = [], iterator = null } = {}) {
    const defaultIterator = (i) => {
      let url_slugs = [i.url_slug];
      let page = { ...i };

      while (page?.parent?.id) {
        page = this.find(page.parent.id, "id");
        url_slugs = [page.url_slug, ...url_slugs];
      }

      return { params: { url_slugs } };
    };

    return this.toPaths({ exclude, includes, iterator: iterator ?? defaultIterator });
  }
}
